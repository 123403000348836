/** Code to initialize analytics tracking with Segment. */

import { AnalyticsBrowser } from "@segment/analytics-next";

/**
 * Segment analytics object, used for tracking page events.
 *
 * This uses "Lazy / Delayed Loading" so no events will actually be sent until
 * the function `analytics.load({ writeKey: "..." })` is called. We load this
 * conditionally in the <Analytics /> component.
 */
export const analytics = new AnalyticsBrowser();
